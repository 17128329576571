// @ts-nocheck

/**
 * EDC REST API
 * All files merged by open api merger
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {Policy} from './policy';

export interface PolicyDefinition {
  policy: Policy;
  uid?: string;
  id: string;
}

/**
 * Temporary workaround around EDC Milestone 6/7 API incompatibilities.
 * @param policyDefinition polcicy definition
 * @return policy definition ID
 */
export function policyDefinitionId(policyDefinition: PolicyDefinition): string {
  return (
    policyDefinition.id ||
    (policyDefinition as any).uid ||
    'no-known-id-field-on-policy-definition'
  );
}
