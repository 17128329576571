import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpApiService} from 'src/app/core/services/api/http-api.service';
import { ConnectorUser } from "src/app/core/models/connectorUser";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private $user: BehaviorSubject<ConnectorUser| null>;

  constructor(private api: HttpApiService,
              ) {
    this.$user = new BehaviorSubject<ConnectorUser | null>(new ConnectorUser());
  }

  setUser(user: ConnectorUser): void {
    this.$user.next(user);
  }

  getUser(): Observable<ConnectorUser | null> {
    return this.$user.asObservable();
  }
}
