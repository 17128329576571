import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {throwError, Observable} from 'rxjs';
import {APP_CONFIG, AppConfig} from '../../config/app-config';
import { AuthService } from "src/app/core/services/auth.service";
import { CatalogApiUrlService } from 'src/app/core/services/api/catalog-api-url.service';


@Injectable()
export class ApiKeyInterceptor implements HttpInterceptor {
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private authService: AuthService,
    private catalogApiUrlService: CatalogApiUrlService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const apiKey = this.getApiKey();
    const isKeycloakReq = req.url.match(/protocol\/openid-connect\/token/)
    const isDownloadFileReq = req.url.match(/api\/files\/downloadFile/)
    const isProvidersInfoReq = req.url == this.catalogApiUrlService.getDataSpaceProvidersApiUrl();
    let headers: any = {}
    if (!isKeycloakReq) {
      const isAuthenticated = this.authService.isAuthenticated()
      if (!isAuthenticated) {
        this.authService.logout()
        return throwError(new Error('Not sending request since user is not authenticated'));
      }
      const token = this.authService.getToken()
      headers['Authorization'] = `Bearer ${token}`
      if (apiKey) {
        headers['X-Api-Key'] = apiKey;
      }
    }
    req = req.clone({
      setHeaders: headers
    });
    return next.handle(req);
  }

  private getApiKey() {
    return this.config.managementApiKey;
  }
}
