// @ts-nocheck

/**
 * EDC REST API
 * All files merged by open api merger
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable:no-unused-variable member-ordering */
import {
  HttpClient,
  HttpContext,
  HttpEvent,
  HttpHeaders,
  HttpParameterCodec,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';
import {Observable} from 'rxjs';
import {Configuration} from '../configuration';
import {CustomHttpParameterCodec} from '../encoder';
// @ts-ignore
import {ContractNegotiationDto} from '../model/contractNegotiationDto';
// @ts-ignore
import {NegotiationId} from '../model/negotiationId';
// @ts-ignore
import {NegotiationInitiateRequestDto} from '../model/negotiationInitiateRequestDto';
// @ts-ignore
import {NegotiationState} from '../model/negotiationState';
// @ts-ignore
import {
  API_KEY,
  BASE_PATH,
  COLLECTION_FORMATS,
  CONNECTOR_DATAMANAGEMENT_API,
} from '../variables';

@Injectable({
  providedIn: 'root',
})
export class ContractNegotiationService {
  public defaultHeaders = new HttpHeaders({'X-Api-Key': this.apiKey});
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;
  protected basePath = 'http://localhost';

  constructor(
    protected httpClient: HttpClient,
    @Inject(CONNECTOR_DATAMANAGEMENT_API) basePath: string,
    @Inject(API_KEY) private apiKey: string,
    @Optional() configuration: Configuration,
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  // @ts-ignore
  private addToHttpParams(
    httpParams: HttpParams,
    value: any,
    key?: string,
  ): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(
    httpParams: HttpParams,
    value?: any,
    key?: string,
  ): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(
          (elem) =>
            (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)),
        );
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(
            key,
            (value as Date).toISOString().substr(0, 10),
          );
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) =>
            (httpParams = this.addToHttpParamsRecursive(
              httpParams,
              value[k],
              key != null ? `${key}.${k}` : k,
            )),
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * Requests aborting the contract negotiation. Due to the asynchronous nature of contract negotiations, a successful response only indicates that the request was successfully received. Clients must poll the /{id}/state endpoint to track the state.
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cancelNegotiation(
    id: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
  ): Observable<any>;
  public cancelNegotiation(
    id: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
  ): Observable<HttpResponse<any>>;
  public cancelNegotiation(
    id: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
  ): Observable<HttpEvent<any>>;
  public cancelNegotiation(
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling cancelNegotiation.',
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected,
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.post<any>(
      `${this.configuration.basePath}/contractnegotiations/${encodeURIComponent(
        String(id),
      )}/cancel`,
      null,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Requests cancelling the contract negotiation. Due to the asynchronous nature of contract negotiations, a successful response only indicates that the request was successfully received. Clients must poll the /{id}/state endpoint to track the state.
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public declineNegotiation(
    id: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
  ): Observable<any>;
  public declineNegotiation(
    id: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
  ): Observable<HttpResponse<any>>;
  public declineNegotiation(
    id: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
  ): Observable<HttpEvent<any>>;
  public declineNegotiation(
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling declineNegotiation.',
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected,
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.post<any>(
      `${this.configuration.basePath}/contractnegotiations/${encodeURIComponent(
        String(id),
      )}/decline`,
      null,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Gets a contract agreement for a contract negotiation with the given ID
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAgreementForNegotiation(
    id: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
  ): Observable<ContractNegotiationDto>;
  public getAgreementForNegotiation(
    id: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
  ): Observable<HttpResponse<ContractNegotiationDto>>;
  public getAgreementForNegotiation(
    id: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
  ): Observable<HttpEvent<ContractNegotiationDto>>;
  public getAgreementForNegotiation(
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getAgreementForNegotiation.',
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected,
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.get<ContractNegotiationDto>(
      `${this.configuration.basePath}/contractnegotiations/${encodeURIComponent(
        String(id),
      )}/agreement`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Gets an contract negotiation with the given ID
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getNegotiation(
    id: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
  ): Observable<ContractNegotiationDto>;
  public getNegotiation(
    id: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
  ): Observable<HttpResponse<ContractNegotiationDto>>;
  public getNegotiation(
    id: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
  ): Observable<HttpEvent<ContractNegotiationDto>>;
  public getNegotiation(
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getNegotiation.',
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected,
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.get<ContractNegotiationDto>(
      `${this.configuration.basePath}/contractnegotiations/${encodeURIComponent(
        String(id),
      )}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Gets the state of a contract negotiation with the given ID
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getNegotiationState(
    id: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
  ): Observable<NegotiationState>;
  public getNegotiationState(
    id: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
  ): Observable<HttpResponse<NegotiationState>>;
  public getNegotiationState(
    id: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
  ): Observable<HttpEvent<NegotiationState>>;
  public getNegotiationState(
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getNegotiationState.',
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected,
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.get<NegotiationState>(
      `${this.configuration.basePath}/contractnegotiations/${encodeURIComponent(
        String(id),
      )}/state`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Returns all contract negotiations according to a query
   * @param offset
   * @param limit
   * @param filter
   * @param sort
   * @param sortField
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getNegotiations(
    offset?: number,
    limit?: number,
    filter?: string,
    sort?: 'ASC' | 'DESC',
    sortField?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
  ): Observable<Array<ContractNegotiationDto>>;
  public getNegotiations(
    offset?: number,
    limit?: number,
    filter?: string,
    sort?: 'ASC' | 'DESC',
    sortField?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
  ): Observable<HttpResponse<Array<ContractNegotiationDto>>>;
  public getNegotiations(
    offset?: number,
    limit?: number,
    filter?: string,
    sort?: 'ASC' | 'DESC',
    sortField?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
  ): Observable<HttpEvent<Array<ContractNegotiationDto>>>;
  public getNegotiations(
    offset?: number,
    limit?: number,
    filter?: string,
    sort?: 'ASC' | 'DESC',
    sortField?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
  ): Observable<any> {
    let localVarQueryParameters = new HttpParams({encoder: this.encoder});
    if (offset !== undefined && offset !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>offset,
        'offset',
      );
    }
    if (limit !== undefined && limit !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>limit,
        'limit',
      );
    }
    if (filter !== undefined && filter !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>filter,
        'filter',
      );
    }
    if (sort !== undefined && sort !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>sort,
        'sort',
      );
    }
    if (sortField !== undefined && sortField !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>sortField,
        'sortField',
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected,
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.get<Array<ContractNegotiationDto>>(
      `${this.configuration.basePath}/contractnegotiations`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Initiates a contract negotiation for a given offer and with the given counter part. Please note that successfully invoking this endpoint only means that the negotiation was initiated. Clients must poll the /{id}/state endpoint to track the state
   * @param negotiationInitiateRequestDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public initiateContractNegotiation(
    negotiationInitiateRequestDto?: NegotiationInitiateRequestDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
  ): Observable<NegotiationId>;
  public initiateContractNegotiation(
    negotiationInitiateRequestDto?: NegotiationInitiateRequestDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
  ): Observable<HttpResponse<NegotiationId>>;
  public initiateContractNegotiation(
    negotiationInitiateRequestDto?: NegotiationInitiateRequestDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
  ): Observable<HttpEvent<NegotiationId>>;
  public initiateContractNegotiation(
    negotiationInitiateRequestDto?: NegotiationInitiateRequestDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
  ): Observable<any> {
    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected,
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Content-Type',
        httpContentTypeSelected,
      );
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.post<NegotiationId>(
      `${this.configuration.basePath}/contractnegotiations`,
      negotiationInitiateRequestDto,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }
}
