import {MDS_THEME, SOVITY_THEME, UBI_SINNOGENES_THEME,UBI_ENFLATE_THEME} from './edc-ui-theme-data';
import {COMMUNITY_EDITION_FEATURES} from './feature-sets/community-edition-features';
import {ENTERPRISE_EDITION_FEATURES} from './feature-sets/enterprise-edition-features';
import {inferEdcUiProfileType} from './infer-edc-ui-profile-type';

/**
 * List of available profiles.
 *
 * This codebase supports multiple profiles since it incorporates multiple deployment targets.
 */
export const EDC_UI_PROFILE_DATA = inferEdcUiProfileType({
  'sovity-open-source': {
    ...SOVITY_THEME,
    routes: 'connector-ui',
    features: new Set(COMMUNITY_EDITION_FEATURES),
  },
  'sovity-hosted-by-sovity': {
    ...SOVITY_THEME,
    routes: 'connector-ui',
    features: new Set(ENTERPRISE_EDITION_FEATURES),
  },
  'mds-open-source': {
    ...MDS_THEME,
    routes: 'connector-ui',
    features: new Set(['mds-fields', ...COMMUNITY_EDITION_FEATURES]),
  },
  'mds-hosted-by-sovity': {
    ...MDS_THEME,
    routes: 'connector-ui',
    features: new Set(['mds-fields', ...ENTERPRISE_EDITION_FEATURES]),
  },
  broker: {
    ...MDS_THEME,
    routes: 'broker-ui',
    features: new Set(['mds-fields']),
  },
  'ubi-sinnogenes': {
    ...UBI_SINNOGENES_THEME,
    routes: 'connector-ui',
    features: new Set(COMMUNITY_EDITION_FEATURES),
  },
  'ubi-enflate': {
    ...UBI_ENFLATE_THEME,
    routes: 'connector-ui',
    features: new Set(COMMUNITY_EDITION_FEATURES),
  },

});
